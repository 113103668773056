<template>
	<div>
		<van-nav-bar
			title="招标详情"
			left-text="返回"
			left-arrow
			@click-left="onClickLeft"
		/>
		<van-cell>
			<template #title>
				<div
					style="
						text-align: center;
						font-size: 20px;
						font-weight: 700;
					"
					v-text="data.title"
				></div>
			</template>
			<template #label>
				<div style="padding: 20px 0; text-align: center">
					<van-tag type="primary" v-if="data.cate">{{
						data.cate
					}}</van-tag>

					<van-tag plain type="primary" v-if="data.area">{{
						data.area
					}}</van-tag>
					|
					<span>{{ data.ann_time }}</span>
				</div>
			</template>
		</van-cell>
		<van-cell class="articleInfo">
			<template #title>
				<div v-html="data.content"></div>
			</template>
		</van-cell>
		<van-cell class="articleInfo">
			<template #title>
				<div v-html="data.content2"></div>
			</template>
		</van-cell>
	</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'ArticleView',
		data() {
			return {
				id: 0,
				data: {
					content: '内容准备中.'
				}
			}
		},
		mounted() {
			const member = JSON.parse(sessionStorage.getItem('member'))
			if(member == undefined){
				this.$toast.fail('你还没有登录');
				return this.$router.push({ path: '/' })
			}
			
			if (member.current_level <= 1) {
				this.$toast.fail('你还不是会员')
				return this.$router.push({ path: '/vip' })
			}
		},
		created() {
			const { id } = this.$route.query
			this.id = id
			if (id) {
				this.onView()
			}
		},
		methods: {
			onView() {
				const that = this
				request
					.get('/bid/article/view', {
						params: { id: that.id }
					})
					.then(res => {
						console.log('res', res)
						const { code, message, data } = res
						that.data = data
						if (code != 200) {
							return that.$toast(message)
						}
					})
			},
			onClickLeft() {
				return window.history.go(-1)
			}
		}
	}
</script>

<style scoped>
.articleInfo {
	width: 100%;
	overflow: auto;
}
table {
	background: #fff;
}
</style>