<template>
	<div>
		<van-nav-bar title="关键词监控" flex>
			<template #right>
				<van-icon name="add-o" size="20" @click="onRight" />
			</template>
		</van-nav-bar>

		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-swipe-cell v-for="item in rows" :key="'cell' + item.id">
					<van-cell :label="'累计数据:' + item.num">
						<template #title>
							{{ item.word }}
							<van-badge
								style="float: right"
								:content="item.new_num"
								max="99"
							/>
						</template>
						<template #label>
							<van-tag type="primary">全国</van-tag>
							更新时间: {{ item.updated_at | formatDate }}
						</template>
					</van-cell>
					<template #right>
						<van-button
							square
							type="primary"
							@click="onView(item)"
							text="查看"
						/>
						<van-button
							square
							type="danger"
							@click="onDelete(item)"
							text="删除"
						/>
					</template>
				</van-swipe-cell>
			</van-list>

			<van-empty
				v-if="rows.length <= 0"
				description="请输入关键词去关注相关项目"
			/>
		</van-pull-refresh>

		<tabs :active="3" />
	</div>
</template>

<script>
	import Tabs from '../components/Tabs'
	import moment from 'moment'

	import request from '@/util/request'
	export default {
		name: 'Word',
		components: {
			Tabs
		},
		data() {
			return {
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: []
			}
		},
		filters: {
			formatDate: function(value) {
				return moment(value * 1000).format('YYYY-MM-DD')
			}
		},
		methods: {
			onRight() {
				this.$router.push({ path: '/add-word' })
			},
			onDelete(item) {
				const that = this
				request
					.delete(`bid/word/delete`, { params: { id: item.id } })
					.then(res => {
						console.log('a', res)
						if (res.code == 200) {
							that.onRefresh()
						} else {
							that.$toast.fail(res.message)
						}
					})
			},
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { queryId, pageNum, pageSize, title, id } = this.$route.query
				if (id) this.id = id
				if (queryId) this.queryId = queryId
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize
				if (title) this.title = title

				const that = this
				request
					.get(`bid/word/index`, {
						params: {
							userId: 0,
							page: this.pageNum,
							pageSize: this.pageSize,
							word: this.value
						}
					})
					.then(item => {
						const { message, code, data } = item
						if (code != 200) {
							that.finished = true
							return that.$toast(message)
						}
						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length <= 0) {
							this.finished = true
						} else {
							this.finished = true
							that.pageNum += 1
						}
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			},
			onView(item) {
				return this.$router.push({
					path: '/article',
					query: { word: item.word }
				})
			},
			onConfirm() {}
		}
	}
</script>