<template>
	<div>
		<van-nav-bar title="申请入驻" />
		<van-form @submit="onSubmit">
			<van-field
				v-model="form.title"
				name="商家名称"
				label="商家名称"
				required
				placeholder="请填写商家名称"
				:rules="[{ required: true, message: '请填写商家名称' }]"
			/>
			<van-field name="image" label="LOGO">
				<template #input>
					<van-uploader v-model="image" :after-read="afterRead" />
				</template>
			</van-field>
			<van-field
				v-model="form.phone"
				name="联系电话"
				label="联系电话"
				required
				placeholder="请填写联系电话"
				:rules="[{ required: true, message: '请填写联系电话' }]"
			/>
			<van-field
				v-model="form.link_main"
				name="联系人"
				label="联系人"
				required
				placeholder="请填写联系人"
				:rules="[{ required: true, message: '请填写联系人' }]"
			/>
			<van-field
				v-model="form.main_product"
				rows="5"
				autosize
				label="主营产品"
				type="textarea"
				maxlength="5000"
				placeholder="请输入主营产品"
				show-word-limit
			/>
			<van-field
				v-model="form.company"
				rows="5"
				autosize
				label="公司介绍"
				type="textarea"
				maxlength="5000"
				placeholder="请输入公司介绍"
				show-word-limit
			/>

			<van-field
				readonly
				clickable
				name="picker"
				:value="limit"
				label="需要置顶天数"
				placeholder="点击选择天数"
				@click="showPicker = true"
				is-link
			/>
			<van-popup v-model="showPicker" position="bottom">
				<van-picker
					show-toolbar
					:columns="columns"
					@confirm="onConfirm"
					@cancel="showPicker = false"
				/>
			</van-popup>

			<van-cell title="入驻年费" value="0.00元" />

			<div style="height: 120px"></div>

			<van-submit-bar
				:price="form.price"
				button-text="免费入驻"
				tip="活动期间，入驻免费"
				tip-icon="info-o"
				type="primary"
			/>
		</van-form>
	</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'CreateShop',
		data() {
			return {
				image: [],
				columns: [
					{ text: '不置顶', value: 0, day: 0 },
					{ text: '5天 ~ 6.00元', value: 6.0, day: 5 },
					{ text: '10天 ~ 10.00元', value: 10.0, day: 10 },
					{ text: '20天 ~ 18.00元', value: 18.0, day: 20 },
					{ text: '30天 ~ 25.00元', value: 25.0, day: 30 },
					{ text: '40天 ~ 30.00元', value: 30.0, day: 40 },
					{ text: '50天 ~ 35.00元', value: 35.0, day: 50 },
					{ text: '60天 ~ 40.00元', value: 40.0, day: 60 }
				],
				limit: '',
				showPicker: false,
				form: {
					title: '',
					image: '',
					year: 1,
					phone: '',
					main_product: '',
					company: '',
					link_main: '',
					address: '',
					top_day: 0,
					price: 0
				}
			}
		},
		methods: {
			onSubmit() {
				const that = this

				const member = JSON.parse(sessionStorage.getItem('member'))
				this.form.open_id = member.openid

				request.post(`bid/bus/create`, this.form).then(res => {
					console.log(res, that)
					const { code, message, data } = res
					console.log('data', data)
					if (code != 200) {
						that.$dialog.alert({ title: '提示', message: message })
					} else {
						that.$dialog
							.alert({ title: '完成', message: message })
							.then(() => {
								that.$router.push({ path: '/info' })
							})
					}
				})
			},
			afterRead(itm) {
				console.log('itm', itm)
				return file => {
					this.$toast.loading({ duration: 0 })
					console.log('file', file)
					request
						.post(`v1/file/base64`, {
							file: file.content
						})
						.then(res => {
							console.log('res', res)
							const { code, message, data } = res
							this.$toast.clear()
							if (code == 200) {
								itm.image = data.url
							} else {
								this.$toast(message)
							}
						})
						.catch(err => {
							this.$toast.clear()
							this.$toast(`${err}`)
						})
				}
			},
			onConfirm(value, index) {
				console.log('value', value, index)
				this.limit = value.text

				this.form.price =  value.value * 100
				this.form.top_day = value.day

				this.showPicker = false
			},
			onCancel() {
				this.$toast('取消')
			}
		}
	}
</script>