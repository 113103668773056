import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { appId, html5Url } from '@/config/index'


Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import { Lazyload } from 'vant';

Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});


router.beforeEach((to, from, next) => {

  // 不是微信环境时, 也跳转授权
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) != 'micromessenger') {
    next()
    return
  }

  // 授权保存
  if (to.name == 'auth') {
    next()
    return
  }

  const workUser = sessionStorage.getItem('weixinUser');
  if (!workUser) {
    sessionStorage.removeItem('weixinUser')
    sessionStorage.setItem('redirectUrl', to.fullPath)

    const redirectUrl = encodeURIComponent(html5Url + 'auth')
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

  } else {
    next()
  }
})

function hightLight(el, binding) {
  const match = binding.value;
  const reg = new RegExp(match, 'g')
  const txt = binding.arg
  let str = '';
  if (txt) {
    str = txt.replace(reg, `<span style="color:#F96600">${match}</span>`)
  } else {
    str = ''
  }
  el.innerHTML = str;
}

Vue.directive('hightlight', {
  bind(el, binding) {
    hightLight(el, binding)
  },
  componentUpdated(el, binding) {
    hightLight(el, binding)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
