<template>
	<div style="line-height: 80px; text-align: center">授权登录中...</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'Auth',
		data() {
			return {
				err: {}
			}
		},
		mounted() {
			const code = this.$route.query.code
			console.log('code:', code)
			if (code) {
				// 获取登录数据
				request
					.get(`/bid/v1/third-party/wechat?code=${code}`)
					.then(res => {
						console.log('res', res)
						const { code, data, message } = res
						this.err = res
						if (code != 200) {
							this.$dialog.confirm({
								title: '提示',
								message: message
							})
						} else {
							const { access_token, member } = data.user_info
							sessionStorage.setItem('accessToken', access_token)
							sessionStorage.setItem('member', JSON.stringify(member))
							sessionStorage.setItem(
								'weixinUser',
								JSON.stringify(data.user_info)
							)
							let redirectUrl = sessionStorage.getItem('redirectUrl')
							this.$router.replace(redirectUrl)
						}
					})
					.catch(e => {
						this.err = e
						console.log('e', e)
					})
			} else {
				console.log('auth res', this.$route.query)
				this.$router.replace('/')
			}
		}
	}
</script>
