<template>
	<div>
		<van-nav-bar title="发布推广" flex />

		<van-sticky :offset-top="height" :z-index="1000">
			<van-button
				type="primary"
				round
				style="float: right; margin-right: 10px"
				@click="onCreate"
				>发布推广</van-button
			>
		</van-sticky>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-cell v-for="item in rows" :key="'cell' + item.id">
					<div slot="icon">
						<div class="icon">
							<img
								:src="
									item.face ||
									'http://skmqz.oss-cn-hangzhou.aliyuncs.com/bus/image/202012/16077380112487.jpg'
								"
							/>
						</div>
						<van-button
							v-if="[1, 2, 3].includes(member_id)"
							size="mini"
							type="danger"
							icon="delete-o"
							@click="onDelete(item)"
						></van-button>
					</div>
					<div slot="title">
						<span>{{ item.nickname || item.open_id }}</span> &nbsp;
						<van-tag v-if="item.is_top > 0" type="primary"
							>顶</van-tag
						>
						<div style="float: right">
							{{ item.created_at | formatDate }}
						</div>
					</div>
					<div slot="label">
						<div class="mainProduct">
							{{ item.content }}
						</div>
						<van-grid :border="false" :column-num="3" :gutter="5">
							<van-grid-item
								v-for="(img, i) in item.images"
								:key="i"
								@click="showImage(item.images, i)"
							>
								<van-image
									fit="cover"
									:height="80"
									:src="img.image"
								>
									<template v-slot:loading>
										<van-loading type="spinner" size="20" />
									</template>
								</van-image>
							</van-grid-item>
						</van-grid>
					</div>
				</van-cell>
			</van-list>

			<van-empty v-if="rows.length <= 0" description="暂无数据" />
		</van-pull-refresh>
		<div style="height: 60px"></div>

		<tabs :active="2" />
	</div>
</template>

<script>
	import Tabs from '../components/Tabs'
	import moment from 'moment'
	import { ImagePreview } from 'vant'

	import request from '@/util/request'
	export default {
		name: 'Word',
		components: {
			Tabs
		},
		data() {
			return {
				height: 400,
				member: {},
				member_id: 0,
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: []
			}
		},
		filters: {
			formatDate: function(value) {
				return moment(value * 1000).format('YYYY-MM-DD')
			}
		},
		created() {
			this.height = document.documentElement.clientHeight - 180
			let member = sessionStorage.getItem('member')
			if (member) {
				this.member = JSON.parse(member)
				this.member_id = this.member.id
			}
		},
		methods: {
			showImage(images, i) {
				let imgs = []
				images.map(it => {
					imgs.push(it.image)
				})
				ImagePreview({
					images: imgs,
					closeable: true,
					messageshowIndex: true,
					startPosition: i
				})
			},
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { queryId, pageNum, pageSize, title, id } = this.$route.query
				if (id) this.id = id
				if (queryId) this.queryId = queryId
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize
				if (title) this.title = title

				const that = this
				request
					.get(`bid/feed/index`, {
						params: {
							page: this.pageNum,
							pageSize: this.pageSize
						}
					})
					.then(item => {
						const { message, code, data } = item
						if (code != 200) {
							that.finished = true
							return that.$toast(message)
						}

						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length < 10) {
							this.finished = true
						} else {
							that.pageNum += 1
						}
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			},
			onView(item) {
				console.log('item', item)
				return this.$router.push({
					path: '/shop-view',
					query: { id: item.id }
				})
			},
			onConfirm() {},
			onDelete(item) {
				const that = this
				request
					.delete(`bid/feed/delete`, { params: { id: item.id } })
					.then(res => {
						const { code, message } = res

						that.$toast(message)
						if (code == 200) {
							that.onRefresh()
						}
					})
			},
			onCreate() {
				return this.$router.push({ path: '/create-post' })
			}
		}
	}
</script>
<style lang="less" scoped>
.icon {
	height: 30px;
	width: 30px;
	border-radius: 15px;
	overflow: hidden;
	margin-right: 10px;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.bodyHeader {
	display: flex;
	margin: 5px 0;
	.mainProduct {
		font-size: 14px;
	}
}
</style>