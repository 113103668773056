import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import Word from '../views/Word.vue'
import Me from '../views/Me.vue'
import Vip from '../views/Vip.vue'
import Article from '../views/Article.vue'
import ArticleView from '../views/ArticleView.vue'
import Recharge from '../views/Recharge.vue'
import Detail from '../views/Detail.vue'
import MyInfo from '../views/MyInfo.vue'
import Auth from '../views/Auth.vue'
import ShopInfo from '../views/ShopInfo.vue'
import ShopView from '../views/ShopView.vue'
import CreatePost from '../views/CreatePost.vue'
import MyArticle from '../views/MyArticle.vue'
import Info from '../views/Info.vue'
import AddWord from '../views/AddWord.vue'
import CreateShop from '../views/CreateShop.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/word',
    name: 'Word',
    component: Word,
  },
  {
    path: '/me',
    name: 'Me',
    component: Me,
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip,
  },
  {
    path: '/article',
    name: 'Article',
    component: Article,
  }, {
    path: '/article-view',
    name: 'ArticleView',
    component: ArticleView,
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: Recharge
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/my-info',
    name: 'MyInfo',
    component: MyInfo
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/shop-info',
    name: 'shop-info',
    component: ShopInfo
  },
  {
    path: '/shop-view',
    name: 'shop-view',
    component: ShopView
  },
  {
    path: '/create-shop',
    name: 'create-shop',
    component: CreateShop
  },
  {
    path: '/create-post',
    name: 'create-post',
    component: CreatePost
  },
  {
    path: '/my-article',
    name: 'my-article',
    component: MyArticle
  },
  {
    path: '/info',
    name: 'info',
    component: Info
  },
  {
    path: '/add-word',
    name: 'add-word',
    component: AddWord
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
