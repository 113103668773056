<template>
	<div>
		<van-nav-bar title="立即充值" />
		<van-form @submit="onSubmit">
			<van-field
				v-model="money"
				name="充值金额"
				type="number"
				label="充值金额"
				placeholder="请输入充值金额"
				:rules="[{ required: true, message: '请填写充值金额' }]"
			/>

			<div style="margin: 16px">
				<van-button
					round
					block
					type="info"
					native-type="submit"
					:disabled="isSubmit"
					>提交</van-button
				>
			</div>
		</van-form>
	</div>
</template>

<script>
	import request from '@/util/request'
	import wx from 'weixin-js-sdk'
	export default {
		name: 'Recharge',
		data() {
			return {
				isSubmit: false,
				money: ''
			}
		},
		created() {
			this.getConfig()
		},
		methods: {
			getConfig() {
				console.log('https://html5.skmqz.cn')
				let params = {}
				params.url = 'https://html5.skmqz.cn/'
				params.jsApiList = JSON.stringify(['chooseWXPay'])
				request
					.post(`bid/v1/third-party/wechat-js-sdk`, params)
					.then(res => {
						console.log('res', res)
						const { code, data } = res
						if (code == 200) {
							wx.config({
								debug: data.debug, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
								appId: data.appId,
								timestamp: data.timestamp,
								nonceStr: data.nonceStr,
								signature: data.signature,
								jsApiList: data.jsApiList
							})
							wx.ready(function() {
								wx.checkJsApi({
									jsApiList: ['chooseWXPay'],
									success: function(res) {
										console.log('seccess')
										console.log(res)
									},
									fail: function(res) {
										console.log('fail')
										console.log(res)
									}
								})
							})
						}
					})
			},
			GetRandomNum(Min, Max) {
				var Range = Max - Min
				var Rand = Math.random()
				return Min + Math.round(Rand * Range)
			},
			onSubmit() {
				this.isSubmit = true
				if (this.money <= 0) {
					this.isSubmit = false
					return this.$toast.fail('金额太小')
				}
				this.$toast.loading('充值中.')
				const member = JSON.parse(sessionStorage.getItem('member'))
				var that = this
				request
					.post(`v1/pay`, {
						order_group: 'recharge',
						pay_type: 1,
						data: {
							body: '微信支付',
							out_trade_no:
								new Date().valueOf() +
								this.GetRandomNum(1000, 9999),
							total_fee: this.money,
							money: this.money
						},
						openid: member.openid,
						trade_type: 'js',
						code: 'aaa',
						money: this.money
					})
					.then(res => {
						const { code, message, data } = res
						that.$toast.loading(message)

						that.isSubmit = false

						if (code == 200) {
							const { config, payStatus } = data
							console.log('payStatus', payStatus, config)
							wx.ready(function() {
								wx.chooseWXPay({
									appId: config.appId,
									nonceStr: config.nonceStr,
									package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
									paySign: config.paySign, // 支付签名
									signType: config.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
									timestamp: config.timestamp,
									success: function(res) {
										console.log('res', res, 'success')
										// 支付成功后的回调函数

										that.$router.push({ path: '/detail' })
									},
									fail: function(res) {
										console.log('支付失败', res, 'fail')
									},
									complete: function(res) {
										console.log(res, 'complete')
									}
								})
							})
						}
					})
			}
		}
	}
</script>