<template>
	<van-tabbar v-model="active">
		<van-tabbar-item
			v-for="(item, index) in menus"
			:key="index"
			:icon="item.icon"
			:url="item.url"
			:dot="item.dot"
			:badge="item.url == '/word' && num > 0 ? num : ''"
			>{{ item.label }}</van-tabbar-item
		>
	</van-tabbar>
</template>

<script>
	export default {
		name: 'Tabs',
		data() {
			return {
				num: 0,
				menus: [
					{ label: '首页', icon: 'home-o', url: '/', dot: false },
					{ label: '开通VIP', icon: 'shop-o', url: '/vip', dot: false },
					{
						label: '商家查询',
						icon: 'comment-o',
						url: 'https://m.tianyancha.com/',
						dot: false
					},
					// {
					// 	label: '关键词监控',
					// 	icon: 'eye-o',
					// 	url: '/word',
					// 	dot: false,
					// 	badge: '0'
					// },
					{ label: '我的', icon: 'user-o', url: '/me', dot: false }
				]
			}
		},
		props: {
			active: {
				type: Number,
				default: 0
			}
		}
	}
</script>