<template>
	<div>
		<van-nav-bar
			:title="data.title"
			left-text="返回"
			left-arrow
			@click-left="onClickLeft"
		/>
		<van-cell-group>
			<van-cell>
				<template #title>
					<div
						style="font-size: 18px; font-weight: 700"
						v-text="data.title"
					></div>
				</template>
				<template #label>
					<div class="bodyHeader">
						<div style="flex: 1">品牌拍档:{{ data.year }}年</div>
						<div style="flex: 1">
							<van-rate
								:value="data.star"
								icon="star"
								void-icon="like-o"
							/>
						</div></div
				></template>
				<div slot="right-icon">
					<div class="icon">
						<img
							src="http://skmqz.oss-cn-hangzhou.aliyuncs.com/bus/image/202012/16077380112487.jpg"
						/>
					</div>
				</div>
			</van-cell>
			<van-cell title="主营产品">
				<template #label>
					<div class="article-body" v-html="data.main_product"></div>
				</template>
			</van-cell>
			<van-cell title="公司介绍">
				<template #label>
					<div class="article-body" v-html="data.company"></div>
				</template>
			</van-cell>
		</van-cell-group>

		<van-cell-group title="联系信息">
			<van-cell :title="data.link_main" />
			<van-cell :title="data.phone" />
			<van-cell :title="data.address" />
		</van-cell-group>

		<van-cell-group title="公司相册"> </van-cell-group>
	</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'ArticleView',
		data() {
			return {
				id: 0,
				data: {
					content: '内容准备中.'
				}
			}
		},
		created() {
			const { id } = this.$route.query
			this.id = id
			if (id) {
				this.onView()
			}
		},
		methods: {
			onView() {
				const that = this
				request
					.get('/bid/bus/view', {
						params: { id: that.id }
					})
					.then(res => {
						console.log('res', res)
						const { code, message, data } = res
						that.data = data
						if (code != 200) {
							return that.$toast(message)
						}
					})
			},
			onClickLeft() {
				return window.history.go(-1)
			}
		}
	}
</script>


<style lang="less" scoped>
.articleInfo {
	width: 100%;
	overflow: auto;
}
table {
	background: #fff;
}
.icon {
	height: 60px;
	width: 60px;
	border-radius: 30px;
	overflow: hidden;
	margin-right: 10px;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.bodyHeader {
	display: flex;
	margin: 5px 0;
	.mainProduct {
		font-size: 14px;
	}
}
/deep/.van-cell__label {
	font-size: 14px;
	line-height: 28px;
	color: #333;
}
</style>