<template>
	<div class="home">
		<van-nav-bar title="" @click-right="onClickRight" fixed :border="false">
			<template #right>
				<div class="right-panel">
					<img src="@/assets/ic_home_vip.png" /> 开通VIP
				</div>
			</template>
		</van-nav-bar>
		<div class="header">
			<img class="logo" src="@/assets/logo.png" />
			<van-tabs
				v-model="active"
				color="#fff"
				title-inactive-color="#FFF"
				title-active-color="#fff"
			>
				<van-tab title="起重项目"></van-tab>
				<van-tab title="防腐项目"></van-tab>
			</van-tabs>
			<van-search
				v-model="value"
				placeholder="请输入搜索关键词"
				@search="onSearch"
			>
			</van-search>
			<div class="homeWord">
				<div class="homeWordImg">
					<img
						style="height: 20px"
						src="@/assets/ic_hot_search.png"
						alt=""
					/>
				</div>
				<div class="homeWordArea">
					<a
						v-for="(item, index) in hotSearch"
						:key="index"
						:href="'/article?word=' + item"
						>{{ item }}</a
					>
				</div>
			</div>
		</div>
		<van-grid :border="false" :column-num="4">
			<van-grid-item
				v-for="(item, index) in navMenu"
				:key="index"
				:icon="item.icon"
				:text="item.label"
				:url="item.url"
			/>
		</van-grid>
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item @click="toVip"
				><img style="height: 80px" src="@/assets/ic_banner_bidwin.png"
			/></van-swipe-item>
			<van-swipe-item
				><img
					style="height: 80px"
					src="@/assets/ic_banner_nizaijian.png"
			/></van-swipe-item>
		</van-swipe>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-cell
					v-for="item in rows"
					:key="'cell' + item.id"
					@click="onView(item)"
				>
					<div slot="title" style="display:flex;">
						<van-tag type="primary" v-if="item.cate2">{{
							item.cate2
						}}</van-tag>
						<img
							:src="getImg(item)"
							style="height: 18px; vertical-align: middle"
						/>
						<div v-html="showKeyWord(item.title)"></div>
					</div>
					<div slot="label">
						<van-tag plain type="primary" v-if="item.area">{{
							item.area
						}}</van-tag>
						<span style="float: right">{{ item.ann_time }}</span>
					</div>
				</van-cell>
			</van-list>
			<van-empty v-if="rows.length <= 0" description="暂无数据" />
		</van-pull-refresh>
		<tabs :active="0" />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Tabs from '../components/Tabs.vue'
	import request from '@/util/request'

	export default {
		name: 'Home',
		components: {
			Tabs
		},
		data() {
			return {
				active: 0,
				value: '',
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: [],
				title: '...',
				column: [],
				navMenu: [
					{
						label: '招标项目',
						icon: require('../assets/icon-1.png'),
						url: 'https://html5.skmqz.cn/article?cate=1&word=起重'
					},
					{
						label: '中标项目',
						icon: require('../assets/icon-2.png'),
						url: 'https://html5.skmqz.cn/article?cate=2&word=起重'
					},
					{
						label: '在建项目',
						icon: require('../assets/icon-3.png'),
						url: 'https://html5.skmqz.cn/article?cate=3&word=起重'
					},

					{
						label: '企业查询',
						icon: require('../assets/icon-3.png'),
						url: 'https://m.tianyancha.com/'
					}
				],
				hotSearch: [
					'起重机',
					'桥式起重机',
					'双梁起重机',
					'防腐',
					'保温'
				]
			}
		},
		methods: {
			getImg(item) {
				let name = 'zhaobiao'
				if (item.py) {
					name = item.py
				}
				return require('@/assets/type_' + name + '.png')
			},
			toVip() {
				return this.$router.push({ path: '/vip' })
			},
			showKeyWord(val) {
				let key = '单梁行车 双梁行车 单梁行吊 单梁天车 电磁起重机 吊钩起重机 欧式行车 欧式行吊 欧式天车 龙门起重机 KBK起重机 葫芦起重机 悬臂起重机 悬臂吊 液压升降平台 架桥机 悬挂起重机 旋臂起重机 冶金起重机 铸造起重机 车间起重机 桥式起重机 门式起重机 起重机 钢结构防腐 设备防腐 污水处理防腐 铁塔防腐 桥梁防腐 护栏防腐 油罐防腐 烟囱防腐 防腐保温 防腐工程 管道防腐 管道保温 电厂防腐 变电站防腐 水厂防腐  防腐 保温';
				// 1.传入关键词数组keyWordArr
				let keyWordArr = key.split(' ');
				let strArr = val.split(' ');
				if(key && key != '') {
					for(let i=0;i<strArr.length;i++) {
						// 2.定制关键词对应正则
						keyWordArr.forEach(e => {
								let regStr = '' + `(${e})`;
								let reg = new RegExp(regStr,"gi");
								// 3.正则替换，关键词飘红
								if(strArr[i].indexOf('span') == -1) {
									strArr[i] = strArr[i].replace(reg, '<span style="color:red;">$1</span>')
								}
							})
						}
					}
				return strArr.join('');
			},
			onClickRight() {
				return this.$router.push({
					path: '/vip'
				})
			},
			onSearch() {
				return this.$router.push({
					path: '/article',
					query: { word: this.value }
				})
			},
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { queryId, pageNum, pageSize, title, id } = this.$route.query
				if (id) this.id = id
				if (queryId) this.queryId = queryId
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize
				if (title) this.title = title

				const that = this
				request
					.get(`bid/article/index`, {
						params: {
							page: this.pageNum,
							pageSize: this.pageSize,
							searchValue: this.searchValue
						}
					})
					.then(item => {
						const { message, code, data } = item
						if (code != 200) {
							that.finished = true
							return that.$toast(message)
						}

						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length <= 0) {
							this.finished = true
						} else {
							that.finished = false
						}
						that.pageNum += 1
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			},
			onView(item) {
				return this.$router.push({
					path: '/article-view',
					query: { id: item.id }
				})
			}
		}
	}
</script>


<style lang="less" scoped>
.header {
	background: url('../assets/ic_home_bg.png');
	background-position: center center;
	padding: 10px 30px;
	text-align: center;
	.logo {
		margin-top: 30px;
		height: 100px;
		text-align: center;
	}
}
/deep/.van-tabs__nav {
	background: none;
}
.van-search {
	background: none;
}
/deep/ .van-nav-bar {
	background: none !important;
	.van-icon {
		color: #f90;
	}
	.right-panel {
		color: #f90;
		background: #39a9ed;
		padding: 3px 15px;
		border-radius: 15px;
		img {
			height: 18px;
			vertical-align: middle;
		}
	}
}

.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	height: 80px;
	text-align: center;
	background-color: #39a9ed;
}
.van-grid {
	background: #f5f5f5;
	.van-grid-item {
		.van-grid-item__content {
			background: #f5f5f5;
		}
	}
}
.homeWord {
	text-align: left;
	display: flex;
	.homeWordImg {
		flex: 1;
		text-align: left;
		border-right: 1px #fff solid;
		img {
			vertical-align: middle;
			height: 18px;
		}
	}
	.homeWordArea {
		height: 18px;
		overflow: hidden;
		a {
			margin: 0 5px;
			color: #fff;
			line-height: 20px;
			font-size: 12px;
		}
	}
}
</style>