<template>
	<div>
		<van-nav-bar title="添加订阅"></van-nav-bar>
		<van-form @submit="onSubmit">
			<van-field
				v-model="word"
				name="多行关键词"
				label="多行关键词"
				rows="10"
				autosize
				type="textarea"
				placeholder="请每行输入10字以内关键词
多个关键词请新起一行"
				:rules="[
					{
						required: true,
						message:
							'请每行输入10字以内关键词,多个关键词请新起一行',
					},
				]"
			/>

			<van-cell title="信息地区" value="全国" />

			<van-goods-action>
				<van-goods-action-button type="info" text="提交" />
			</van-goods-action>
		</van-form>
	</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'AddWord',
		data() {
			return {
				word: '',
				password: ''
			}
		},
		methods: {
			onSubmit() {
				request.post(`/bid/word/create`, { word: this.word }).then(res => {
					const { code, message, data } = res
					console.log('data', data)
					if (code != 200) {
						return this.$dialog.alert({
							title: '提示',
							message: message
						})
					}
					this.$toast.success('添加完成')
					return this.$router.push({ path: '/word' })
				})
			}
		}
	}
</script>