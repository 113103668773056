<template>
	<div>
		<van-search
			v-model="value"
			round
			show-action
			placeholder="请输入搜索关键词"
			@search="onSearch(false)"
		>
		</van-search>
		<van-dropdown-menu>
			<van-dropdown-item
				title="地区"
				v-model="value1"
				:options="option1"
			/>
			<van-dropdown-item
				title="分类"
				v-model="value2"
				:options="option2"
			/>
			<van-dropdown-item
				title="时间"
				v-model="value4"
				:options="option4"
			/>
			<van-dropdown-item title="更多" :options="option3" />
		</van-dropdown-menu>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-cell
					v-for="item in rows"
					:key="'cell' + item.id"
					@click="onView(item)"
				>
					<div slot="title">
						<van-tag type="primary">{{ item.cate2 }}</van-tag>
						<img
							:src="getImg(item)"
							style="height: 18px; vertical-align: middle"
						/>
						<span v-hightlight:[item.title]="value">{{
							item.title
						}}</span>
					</div>
					<div slot="label">
						<van-tag plain type="primary" v-if="item.area">{{
							item.area
						}}</van-tag>
						<span style="float: right">{{ item.ann_time }}</span>
					</div>
				</van-cell>
			</van-list>
			<van-empty v-if="rows.length <= 0" description="暂无数据" />
		</van-pull-refresh>
	</div>
</template>

<script>
	import request from '@/util/request'
	export default {
		name: 'Article',
		data() {
			return {
				value: '',
				value1: 0,
				value2: 'a',
				value3: 'a',
				value4: 'a',
				option1: [
					{ text: '地区', value: '' },
					{ text: '湖北', value: '湖北' },
					{ text: '黑龙江', value: '黑龙江' },
					{ text: '北京', value: '北京' },
					{ text: '云南', value: '云南' },
					{ text: '上海', value: '上海' },
					{ text: '重庆', value: '重庆' },
					{ text: '天津', value: '天津' },
					{ text: '江苏', value: '江苏' },
					{ text: '青海', value: '青海' },
					{ text: '河北', value: '河北' },
					{ text: '河南', value: '河南' },
					{ text: '海南', value: '海南' }
				],
				option2: [
					{ text: '全部', value: '' },
					{ text: '招标', value: '招标' },
					{ text: '预告', value: '预告' },
					{ text: '预审', value: '预审' },
					{ text: '询价', value: '询价' },
					{ text: '竞谈', value: '竞谈' },
					{ text: '单一', value: '单一' },
					{ text: '竞价', value: '竞价' },
					{ text: '邀标', value: '邀标' }
				],
				option3: [
					{ text: '价格', value: 'a' },
					{ text: '搜索', value: 'b' }
				],
				option4: [
					{ text: '全部', value: 'all' },
					{ text: '当天', value: 'day' },
					{ text: '24小时内', value: '24' },
					{ text: '三天内', value: '3day' },
					{ text: '近一个月', value: 'month' },
					{ text: '近半年', value: 'haff' },
					{ text: '一年内', value: 'year' }
				],
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: [],
				title: '...',
				column: []
			}
		},
		mounted() {
			const { word } = this.$route.query
			this.value = word
			console.log('word', word)
		},
		methods: {
			getImg(item) {
				let name = 'zhaobiao'
				if (item.py) {
					name = item.py
				}
				return require('@/assets/type_' + name + '.png')
			},
			onSearch(isWord) {
				this.onRefresh()
				if (isWord) {
					this.$toast.success('监控成功')
				}
				request.post(`/bid/word/create`, { word: this.value }).then(res => {
					console.log('word', res)
					let { code, message } = res
					if (code != 200) {
						this.$dialog.alert({ title: '提示', message: message })
					}
				})
			},
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { queryId, pageNum, pageSize, title, id } = this.$route.query
				if (id) this.id = id
				if (queryId) this.queryId = queryId
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize
				if (title) this.title = title

				const that = this
				request
					.get(`bid/article/index`, {
						params: {
							page: this.pageNum,
							pageSize: this.pageSize,
							word: this.value
						}
					})
					.then(item => {
						const { message, code, data } = item
						if (code != 200) {
							that.finished = true
							return that.$toast(message)
						}

						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length <= 0) {
							this.finished = true
						} else {
							that.finished = false
						}
						that.pageNum += 1
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			},
			onView(item) {
				return this.$router.push({
					path: '/article-view',
					query: { id: item.id }
				})
			},
			onConfirm() {}
		}
	}
</script>