import axios from 'axios'
import { apiUrl } from '@/config'

axios.defaults.timeout = 70000
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'


const request = axios.create({
  withCredentials: false,
  baseURL: apiUrl
})

request.interceptors.request.use(
  config => {
    let access_token = ''

    if (process.env.NODE_ENV == 'development') {
      access_token = 'AZrST5rEUA8iq3M1Qt3I7gjfHaodlqQG_1592032504'
    } else {
      access_token = sessionStorage.getItem('accessToken')
    }

    config.headers.common['x-api-key'] = `${access_token}`
    return config
  },
  error => {
    console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

request.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

export default request
