<template>
	<div>
		<van-nav-bar
			title="开通起重防腐招标采购网VIP"
			fixed
			left-arrow
			left-text="返回"
			@click-left="onClickLeft"
		/>
		<div style="height: 60px"></div>
		<van-grid :column-num="3" :border="false">
			<van-grid-item
				v-for="(item, index) in prices"
				:key="index"
				@click="selectYear(index)"
			>
				<div class="price-item" :class="{ active: index == active }">
					<div class="label">{{ item.label }}</div>
					<div class="price">
						¥<span>{{ item.price }}</span>
					</div>
					<!-- <div class="org">¥{{ item.orgPrice }}.00</div>
					<span class="minus">
						立减¥{{ item.orgPrice - item.price }}元
					</span> -->
				</div>
			</van-grid-item>
		</van-grid>
		<van-cell-group title="会员权益说明">
			<van-cell
				title="全国项目实时查询"
				label="实时查询全国招标,采购等项目信息"
			/>
			<van-cell
				title="提升中标分析"
				label="为您分析潜在竞标人,提高中标几率"
			/>
			<van-cell
				title="直接联系项目负责人"
				label="一键拔打项目负责人电话,成功更进一步"
			/>
			<van-cell
				title="项目信息定制推送"
				label="24小时实时动态更新, 高效获取招标信息"
			/>

			<van-cell title="企业查询" label="多维度监测企业, 全方位了解对手" />
			<van-cell title="只有VIP会员" label="功能全开放, 更多功能新增中" />
		</van-cell-group>
		<div style="height: 80px"></div>
		<van-goods-action>
			<van-goods-action-button
				type="danger"
				text="立即支付"
				@click="onClickButton"
			/>
		</van-goods-action>
	</div>
</template>

<script>
	import request from '@/util/request'
	import wx from 'weixin-js-sdk'

	export default {
		name: 'Vip',
		data() {
			return {
				active: 0,
				prices: [
					{ label: '1个月VIP', price: 222, orgPrice: 7200 },
					{ label: '6个月VIP', price: 666, orgPrice: 14400 },
					{ label: '12个月VIP', price: 999, orgPrice: 21600 }
				]
			}
		},
		created() {
			this.getConfig()
		},
		mounted() {
			const member = JSON.parse(sessionStorage.getItem('member'))
			if(member == undefined){
				this.$toast.fail('你还没有登录');
				
			}else{
				if (member.current_level > 1) {
					this.$toast.fail('你已经是VIP会员')
					return this.$router.push({ path: '/me' })
				}
			}
			
		},
		methods: {
			getConfig() {
				console.log('https://html5.skmqz.cn')
				let params = {}
				params.url = 'https://html5.skmqz.cn/'
				params.jsApiList = JSON.stringify(['chooseWXPay'])
				request
					.post(`bid/v1/third-party/wechat-js-sdk`, params)
					.then(res => {
						console.log('res', res)
						const { code, data } = res
						if (code == 200) {
							wx.config({
								debug: data.debug, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
								appId: data.appId,
								timestamp: data.timestamp,
								nonceStr: data.nonceStr,
								signature: data.signature,
								jsApiList: data.jsApiList
							})
							wx.ready(function() {
								wx.checkJsApi({
									jsApiList: ['chooseWXPay'],
									success: function(res) {
										console.log('seccess')
										console.log(res)
									},
									fail: function(res) {
										console.log('fail')
										console.log(res)
									}
								})
							})
						}
					})
			},
			selectYear(year) {
				this.active = year
			},
			onClickLeft() {
				return this.$router.push({ path: '/' })
			},
			GetRandomNum(Min, Max) {
				var Range = Max - Min
				var Rand = Math.random()
				return Min + Math.round(Rand * Range)
			},
			onClickButton() {
				let price = this.prices[this.active].price
				const that = this
				const member = JSON.parse(sessionStorage.getItem('member'))
				request
					.post(`/bid/vip/create`, {
						year: this.active + 1,
						money: price,
						order_group: 'order',
						pay_type: 1,
						openid: member.openid,
						trade_type: 'js',
						code: 'aaa',
						data: {
							body: '微信支付',
							out_trade_no: new Date().valueOf() + that.GetRandomNum(1000, 9999),
							total_fee: price,
							money: price,
							order_id: that.active
						}
					})
					.then(res => {
						const { code, message, data } = res
						that.$toast.loading(message)

						that.isSubmit = false

						if (code == 200) {
							const { config, payStatus } = data
							console.log('payStatus', payStatus, config)
							wx.ready(function() {
								wx.chooseWXPay({
									appId: config.appId,
									nonceStr: config.nonceStr,
									package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
									paySign: config.paySign, // 支付签名
									signType: config.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
									timestamp: config.timestamp,
									success: function(res) {
										console.log('res', res, 'success')
										// 支付成功后的回调函数

										that.$router.push({ path: '/me' })
									},
									fail: function(res) {
										console.log('支付失败', res, 'fail')
									},
									complete: function(res) {
										console.log(res, 'complete')
									}
								})
							})
						}
					})
			}
		}
	}
</script>
<style lang="less" scoped>
.price-item {
	border: 1px #ccc solid;
	background: #fff;
	width: 100%;
	border-radius: 10px 10px;
	text-align: center;
	padding: 20px 0;

	.label {
		color: #999;
		font-size: 16px;
		font-weight: 700;
	}
	.price {
		color: #e1bf78;
		font-size: 12px;
		margin: 10px 0;
		span {
			font-size: 30px;
			font-weight: 700;
		}
	}
	.org {
		font-size: 14px;
		color: #999;
		text-decoration: line-through;
		margin: 10px 0;
	}
	.minus {
		background: #e1bf78;
		color: #fff;
		font-size: 12px;
		border-radius: 10px 10px;
		padding: 1px 10px;
	}
}
.active {
	border: 2px #e1bf78 solid;
	background: #fff8f3;
}
</style>