<template>
	<div>
		<van-nav-bar title="商家信息" flex />

		<van-empty description="旧数据迁移中" />
	</div>
</template>

<script>
	export default {
		name: 'ShopInfo',
		data() {
			return {}
		}
	}
</script>