<template>
	<div>
		<div class="header">
			<van-cell-group :border="false">
				<van-cell>
					<template #title>
						<h2 style="margin: 10px 0">
							{{ member.nickname }}
						</h2>
					</template>
					<template #icon>
						<div class="face">
							<img
								:src="
									member.head_portrait ||
									require('@/assets/missing-face.png')
								"
								width="60"
							/>
						</div>
					</template>
					<template #label>
						余额: {{ account.user_money || '-.--' }}<br />

						<span class="vip">
							{{ memberLevel.name || '普通会员' }}
							<span v-if="member.endTime"
								>到期时间: {{ member.endTime }}</span
							>
						</span>
					</template>
				</van-cell>
			</van-cell-group>
			<img src="@/assets/arc.png" class="header_arc" />
		</div>

		<div class="my-body">
			<van-cell-group
				class="my-body-items"
				v-for="(item, index) in menus"
				:key="index"
				:title="item.label"
			>
				<van-cell
					v-for="(sub, i) in item.menus"
					:key="i"
					:title="sub.label"
					:icon="sub.icon"
					is-link
					:url="sub.url"
				/>
			</van-cell-group>
		</div>
		<div class="" style="height: 80px"></div>

		<tabs :active="4" />
	</div>
</template>

<script>
	import Tabs from '../components/Tabs'
	import request from '@/util/request'

	export default {
		name: 'Me',
		components: {
			Tabs
		},
		data() {
			return {
				member: {
					nickname: '会员名称',
					head_portrait: ''
				},
				account: {
					user_money: ''
				},
				memberLevel: {
					name: ''
				},
				menus: [
					{
						label: '会员',
						menus: [
							{
								label: '立即充值',
								icon: 'balance-o',
								url: '/recharge'
							},
							{
								label: '余额明细',
								icon: 'gold-coin-o',
								url: '/detail'
							},
							{ label: 'VIP等级', icon: 'vip-card-o', url: '/vip' }
						]
					},
					{
						label: '功能',
						menus: [
							{
								label: '商机列表',
								icon: 'notes-o',
								url: '/my-info'
							},
							// { label: '关键词监控', icon: 'search', url: '/word' },
							{
								label: '关注信息',
								icon: 'completed',
								url: '/my-article'
							}
						]
					}
				]
			}
		},
		created() {
			this.getMemberInfo();
			// let member = sessionStorage.getItem('member')
			// if (member) {
			// 	this.member = JSON.parse(member)
			// 	this.memberLevel = this.member.memberLevel
			// 	this.account = this.member.memberLevel
			// }
			// console.log('member', member)
		},
		methods: {
			getMemberInfo()
			{
				const t = this;
					let member = sessionStorage.getItem('member')
					if(member == undefined || member == '[]') {
						member = {
							id: 3
						};
					}else{
						member = JSON.parse(member);
						console.log(member)
					}
					request.get(`v1/member/member/${member.id}`).then(res => {
						console.log('res', res)
						sessionStorage.setItem('member', JSON.stringify(res.data))

						t.member = res.data
						t.memberLevel = res.data.memberLevel
						t.account = res.data.account
					})
			}
		}
	}
</script>


<style lang="less" scoped>
.header {
	position: relative;
	background: #1989fa url('../assets/ic_user_bg.png') no-repeat;
	height: 150px;
	color: #fff;
	.van-cell-group {
		background: none;
		padding: 20px 30px;
		.van-cell {
			background: none;
			h2 {
				color: #fff;
			}
			.van-cell__label {
				color: #f1f1f1;
			}
			.face {
				margin-right: 20px;
				img {
					width: 60px;
					height: 60px;
					background: #fff;
					border-radius: 30px;
				}
			}
			.vip {
				background: #0f9c;
				margin-top: 20px;
				padding: 5px 10px;
				border-radius: 5px 5px;
			}
		}
	}
	.header_arc {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
.my-body {
	background: #f5f5f6;
	.van-cell__left-icon {
		color: #1989fa;
		font-size: 20px;
	}
	.van-cell-group__title {
		margin-top: 10px;
		padding-top: 10px;
		background: #fff;
	}
}
</style>