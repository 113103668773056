<template>
	<div>
		<van-nav-bar title="余额明细" flex />
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-cell
					v-for="item in rows"
					:key="'cell' + item.id"
					:title="'变动金额:' + item.num"
					:value="'当前余额:' + item.new_num"
					:label="item.remark"
				>
					<template #label>
						备注: {{ item.remark }}
						<div>更新时间:{{ item.updated_at | formatDate }}</div>
					</template>
				</van-cell>
			</van-list>

			<van-empty v-if="rows.length <= 0" description="暂无数据" />
		</van-pull-refresh>
	</div>
</template>

<script>
	import request from '@/util/request'
	import moment from 'moment'

	export default {
		name: 'Detail',
		components: {},
		data() {
			return {
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: []
			}
		},
		filters: {
			formatDate: function(value) {
				return moment(value * 1000).format('YYYY-MM-DD HH')
			}
		},
		methods: {
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { pageNum, pageSize } = this.$route.query
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize

				this.refreshing = false
				const that = this
				request
					.get(`bid/detail/index`, {
						params: {
							page: this.pageNum,
							pageSize: this.pageSize
						}
					})
					.then(item => {
						that.loading = true

						that.finished = true
						const { message, code, data } = item
						if (code != 200) {
							that.finished = false
							return that.$toast(message)
						}

						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length <= 0) {
							this.finished = true
						} else {
							that.finished = true
							that.pageNum += 1
						}
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			}
		}
	}
</script>