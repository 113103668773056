<template>
	<div>
		<van-nav-bar title="商家入驻" flex />
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<van-cell
					v-for="item in rows"
					:key="'cell' + item.id"
					@click="onView(item)"
				>
					<div slot="icon">
						<div class="icon">
							<img
								src="http://skmqz.oss-cn-hangzhou.aliyuncs.com/bus/image/202012/16077380112487.jpg"
							/>
						</div>
					</div>
					<div slot="title" class="title">
						{{ item.title }}
					</div>
					<div slot="label">
						<div class="bodyHeader">
							<div style="flex: 1">
								品牌拍档:{{ item.year }}年
							</div>
							<div style="flex: 1">
								<van-rate
									:value="item.star - 0"
									icon="star"
									void-icon="like-o"
									:size="10"
								/>
							</div>
							<div style="flex: 1">
								<van-tag v-if="item.is_top > 0" type="primary"
									>顶</van-tag
								>
							</div>
						</div>
						<div class="mainProduct">
							{{ item.main_product }}
						</div>
					</div>
				</van-cell>
			</van-list>

			<van-empty v-if="rows.length <= 0" description="正在迁移" />
		</van-pull-refresh>
		<div style="height: 60px"></div>

		<tabs :active="1" />
	</div>
</template>

<script>
	import Tabs from '../components/Tabs'

	import request from '@/util/request'
	export default {
		name: 'Word',
		components: {
			Tabs
		},
		data() {
			return {
				searchValue: '',
				loading: false,
				finished: false,
				refreshing: false,
				queryId: 0,
				id: 0,
				pageNum: 1,
				pageSize: 20,
				rows: []
			}
		},
		methods: {
			onLoad() {
				if (this.refreshing) {
					this.rows = []
					this.refreshing = false
				}

				const { queryId, pageNum, pageSize, title, id } = this.$route.query
				if (id) this.id = id
				if (queryId) this.queryId = queryId
				if (pageNum) this.pageNum = pageNum
				if (pageSize) this.pageSize = pageSize
				if (title) this.title = title

				const that = this
				request
					.get(`bid/bus/index`, {
						params: {
							page: this.pageNum,
							pageSize: this.pageSize
						}
					})
					.then(item => {
						const { message, code, data } = item
						if (code != 200) {
							that.finished = true
							return that.$toast(message)
						}

						// 数据
						that.rows.push(...data)

						that.loading = false

						if (data.length < 10) {
							this.finished = true
						} else {
							that.pageNum += 1
						}
					})
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false
				this.rows = []
				this.pageNum = 1

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true
				this.onLoad()
			},
			onView(item) {
				console.log('item', item)
				return this.$router.push({
					path: '/shop-view',
					query: { id: item.id }
				})
			},
			onConfirm() {}
		}
	}
</script>
<style lang="less" scoped>
.icon {
	height: 60px;
	width: 60px;
	overflow: hidden;
	margin-right: 10px;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.title {
	color: #000;
	font-size: 14px;
	font-weight: 700;
}
.bodyHeader {
	display: flex;
	margin: 5px 0;
}
.mainProduct {
	font-size: 12px;
	color: #333;
}
</style>